import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import 'font-awesome/css/font-awesome.min.css';


const Home = lazy(() => import('./pages/Home'));
const Services = lazy(() => import('./pages/Services'));
const Booking = lazy(() => import('./pages/Booking'));
const ServicePage = lazy(() => import('./pages/ServicePage'));
const Testimonials = lazy(() => import('./pages/About/Testimonial'));
const About = lazy(() => import('./pages/About'));
const TermiteControl = lazy(() => import('./pages/ServicesItems/termite-control'));
const MosquitoControlPage = lazy(() => import('./pages/ServicesItems/mosquito-control'));
const RodentControlPage = lazy(() => import('./pages/ServicesItems/rodent-control'));
const CockroachControl = lazy(() => import('./pages/ServicesItems/cockroach-control'));
const FleasControl = lazy(() => import('./pages/ServicesItems/fleas-ticks'));
const CatControl = lazy(() => import('./pages/ServicesItems/cat-control'));
const LizardControl = lazy(() => import('./pages/ServicesItems/lizard-control'));
const SpiderControl = lazy(() => import('./pages/ServicesItems/spider-control'));
const HoneyControl = lazy(() => import('./pages/ServicesItems/honey-bee'));
const PigeonControl = lazy(() => import('./pages/ServicesItems/pigeon'));
const BedBugs = lazy(() => import('./pages/ServicesItems/bed-bugs'));
const Ants = lazy(() => import('./pages/ServicesItems/carpenter-ants'));
const Snakes = lazy(() => import('./pages/ServicesItems/snakes-control'));
const Turf = lazy(() => import('./pages/ServicesItems/horticultural-turf'));
const FlyControl = lazy(() => import('./pages/ServicesItems/fly-control'));
const YardServices = lazy(() => import('./pages/ServicesItems/yard-services'));
const Sanitization = lazy(() => import('./pages/ServicesItems/sanitization'));
const Beetles = lazy(() => import('./pages/ServicesItems/powder-post-beetles'));
const Contact = lazy(() => import('./pages/Contact'));
const PrivacyPolicy = lazy(() => import('./pages/privacy-policy'));
const TermsAndConditions = lazy(() => import('./pages/terms-and-conditions'));
const Review = lazy(() => import('./pages/review'));
const ResidentialPestControl = lazy(() => import('./pages/ResidentialPestControl'));
const CommercialPestControl = lazy(() => import('./pages/Commercial/CommercialPestControl'));
const FAQPage = lazy(() => import('./pages/About/Faq'));
const AboutCompany = lazy(() => import('./pages/About/about-company'));
const EnvironmentPolicy = lazy(() => import('./pages/About/environment-policy'));
const QualityPolicy = lazy(() => import('./pages/About/quality-policy'));
const SafetyMeasures = lazy(() => import('./pages/About/safety-measures'));
const Vendors =lazy(() => import('./pages/About/vendor-registered'));
const ShoppingMall =lazy(() => import('./pages/Commercial/shopping-mall'));
const BuildingCon =lazy(() => import('./pages/Commercial/buildings-contructions'));
const Edu =lazy(() => import('./pages/Commercial/educational-institutions'));
const Factories =lazy(() => import('./pages/Commercial/factories'));
const Office =lazy(() => import('./pages/Commercial/office-facilities'));
const Food =lazy(() => import('./pages/Commercial/food-manufacturings'));
const Warehousing =lazy(() => import('./pages/Commercial/warehousing'));
const GovtOff =lazy(() => import('./pages/Commercial/government-offices'));
const Hotel =lazy(() => import('./pages/Commercial/hotels-rooms'));
const Hospital =lazy(() => import('./pages/Commercial/hospitals-clinics'));

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/:slug" element={<ServicePage />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/about/testimonials" element={<Testimonials />} />
            <Route path="/about" element={<About />} />
            <Route path="/services/termite-control" element={<TermiteControl />} />
            <Route path="/services/mosquito-control" element={<MosquitoControlPage />} />
            <Route path="/services/rodent-control" element={<RodentControlPage/>} />
            <Route path="/services/cockroach-control" element={<CockroachControl/>} />
            <Route path="/services/fleas-ticks" element={<FleasControl/>} />
            <Route path="/services/cat-control" element={<CatControl/>} />
            <Route path="/services/lizard-control" element={<LizardControl/>} />
            <Route path="/services/spider-control" element={<SpiderControl />} />
            <Route path="/services/honey-bee" element={<HoneyControl />} />
            <Route path="/services/pigeon" element={<PigeonControl />} />
            <Route path="/services/bed-bugs" element={<BedBugs />} />
            <Route path="/services/carpenter-ants" element={<Ants />} />
            <Route path="/services/snakes-control" element={<Snakes />} />
            <Route path="/services/horticultural-&-turf" element={<Turf />} />
            <Route path="/services/powder-post-beetles" element={<Beetles />} />
            <Route path="/services/fly-control" element={<FlyControl />} />
            <Route path="/services/yard-services" element={<YardServices />} />
            <Route path="/services/sanitization" element={<Sanitization />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/review" element={<Review />} />
            <Route path="/services/residential" element={<ResidentialPestControl />} />
            <Route path="/commercial" element={<CommercialPestControl />} />
            <Route path="/about/faq" element={<FAQPage />} />
            <Route path="/about/about-company" element={<AboutCompany />} />
            <Route path="/about/environment-policy" element={<EnvironmentPolicy />} />
            <Route path="/about/quality-policy" element={<QualityPolicy />} />
            <Route path="/about/safety-measures" element={<SafetyMeasures />} />
            <Route path="/about/vendor-registered" element={<Vendors />} />
            <Route path="/Commercial/shopping-mall" element={<ShoppingMall />} />
            <Route path="/Commercial/buildings-&-contructions" element={<BuildingCon />} />
            <Route path="/Commercial/educational-institutions" element={<Edu />} />
            <Route path="/Commercial/factories" element={<Factories />} />
            <Route path="/Commercial/office-facilities" element={<Office />} />
            <Route path="/Commercial/food-&-manufacturings" element={<Food />} />
            <Route path="/Commercial/warehousing" element={<Warehousing />} />
            <Route path="/Commercial/government-offices" element={<GovtOff />} />
            <Route path="/Commercial/hotels-&-rooms" element={<Hotel />} />
            <Route path="/Commercial/hospitals-&-clinics" element={<Hospital />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </Router>
  );
};

export default App;

