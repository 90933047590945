import React from 'react';
import { Link } from 'react-router-dom';
import { Phone, Mail, Clock, MapPin, PrinterCheck } from 'lucide-react';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      {/* Footer Section */}
      <footer className="bg-gray-50 py-12 px-4 text-gray-700">
        <div className="container mx-auto max-w-7xl">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Contact Section */}
            <div className="space-y-5">
              <p className="text-gray-600 font-bold text-x md-5 ">
                Do you have questions or want more information? Contact us now
              </p>
              <div className="space-y-4">
                <a
                  href="tel:+966502955698"
                  className="flex items-center space-x-3 text-gray-600 hover:text-blue-600 transition-colors"
                >
                  <Phone size={24} className="text-green-600" />
                  <span className="font-bold text-xl">+966 13 3611 007</span>
                </a>
                <a
                  href="fax:+9660133618601"
                  className="flex items-center space-x-3 text-gray-600 hover:text-blue-600 transition-colors"
                >
                  <PrinterCheck  size={24} className="text-green-600" />
                  <span className="font-bold text-xl">+966 01 33618 601</span>
                </a>
                <a
                  href="mailto:info@gefpestcontrolksa.com"
                  className="flex items-center space-x-3 text-gray-600 hover:text-blue-600 transition-colors"
                >
                  <Mail size={24} className="text-green-600" />
                  <span className="font-bold text-xl">info@gefpestcontrolksa.com</span>
                </a>
              </div>
            </div>

            {/* Services Section */}
            <div className="space-y-5">
              <h3 className="text-lg font-semibold text-gray-900 mb-3">Services</h3>
              <ul className="space-y-4">
                {[
                  { to: 'services/termite-control', label: 'Termite Control' },
                  { to: '/services/rodent-control', label: 'Rodent Control' },
                  { to: '/services/mosquito-control', label: 'Mosquito Control' },
                  { to: '/services/bed-bugs', label: 'Bed Bug Control' },
                ].map((link) => (
                  <li key={link.to}>
                    <Link
                      to={link.to}
                      className="text-gray-600 hover:text-blue-600 transition-colors flex items-center space-x-2"
                    >
                      <span>✦</span>
                      <span>{link.label}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Support Links Section */}
            <div className="space-y-5">
              <h3 className="text-lg font-semibold text-gray-900 mb-3">Support</h3>
              <ul className="space-y-4">
                {[
                  { to: '/services', label: 'Services' },
                  { to: '/privacy-policy', label: 'Privacy Policy' },
                  { to: '/terms-and-conditions', label: 'Terms and Conditions' },
                  { to: '/about/testimonials', label: 'Testimonials' },
                ].map((link) => (
                  <li key={link.to}>
                    <Link
                      to={link.to}
                      className="text-gray-600 hover:text-blue-600 transition-colors flex items-center space-x-2"
                    >
                      <span>✦</span>
                      <span>{link.label}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Office Info Section */}
            <div className="space-y-5">
              <h3 className="text-lg font-semibold text-gray-900 mb-3">Office</h3>
              <div className="space-y-4">
                <div className="flex items-start space-x-3">
                  <MapPin size={18} className="text-green-600 mt-1 flex-shrink-0" />
                  <address className="text-gray-600 not-italic">
                    4018, King Abdullah Road, Jubail City Center, Al Jubayl, Saudi Arabia 35514
                  </address>
                </div>
                <div className="flex items-center space-x-3">
                  <Clock size={18} className="text-green-600 flex-shrink-0" />
                  <p className="text-gray-600">Sun - Thu: 08:00 AM - 06:00 PM</p>
                </div>
              </div>

              {/* Social Media Icons */}
              <div className="flex space-x-6 mt-5">
                <a
                  href="https://facebook.com/gefpestcontrol"
                  className="text-gray-600 hover:text-green-600 transition-colors"
                >
                  <FaFacebookF size={20} />
                </a>
                <a
                  href="https://instagram.com/gefpestcontrol"
                  className="text-gray-600 hover:text-green-600 transition-colors"
                >
                  <FaInstagram size={20} />
                </a>
                <a
                  href="https://twitter.com/gefpestcontrol"
                  className="text-gray-600 hover:text-green-600 transition-colors"
                >
                  <FaTwitter size={20} />
                </a>
                <a
                  href="https://linkedin.com/gefpestcontrol"
                  className="text-gray-600 hover:text-green-600 transition-colors"
                >
                  <FaLinkedinIn size={20} />
                </a>
              </div>
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="mt-12 pt-8 border-t border-gray-200">
            <p className="text-center text-gray-600">
              &copy; {currentYear} GEF Pest Control. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
