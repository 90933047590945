import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { ChevronDown, House } from "lucide-react";
import LogoImage from "../assets/LOGO.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);
  const [isCommercialDropdownOpen, setIsCommercialDropdownOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);
  const toggleServicesDropdown = () =>
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  const toggleAboutDropdown = () =>
    setIsAboutDropdownOpen(!isAboutDropdownOpen);
  const toggleCommercialDropdownOpen =() =>
    setIsCommercialDropdownOpen (!isCommercialDropdownOpen);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY && currentScrollY > 50) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const services = [
    "Rodent Control",
    "Spider Control",
    "Termite Control",
    "Cockroach Control",
    "Snakes Control",
    "Cat Control",
    "Lizard Control",
    "Mosquito Control",
    "Bed Bugs",
    "Powder Post Beetles",
    "Fleas Ticks",
    "Carpenter Ants",
    "Horticultural & Turf",
    "Yard Services",
    "Honey Bee",
    "Fly Control",
    "Pigeon",
    "Sanitization",
  ];

  const aboutItems = ["About Company", "Environment Policy", "Quality Policy", "Safety Measures", "Vendor Registered", "Testimonials", "FAQ"];
  const ComItems = ["Shopping Mall", "Office Facilities", "Hospitals & Clinics", "Warehousing", "Hotels & Rooms", "Factories", "Buildings & Contructions", "Food & Manufacturings", "Educational Institutions", "Government Offices",];

  return (
    <nav
      className={`${
        isVisible ? "top-0" : "-top-20"
      } fixed w-full bg-white shadow-lg transition-all duration-300 z-50`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-18">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-2">
            <img
              src={LogoImage}
              alt="GEF Pest Control Logo"
              className="h-16 w-auto object-contain"
            />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <motion.li className="list-none font-medium">
              <Link to="/" className="hover:text-blue-600">
              <House className="w-5 h-5" />
              </Link>
            </motion.li>
            {/* Residencial */}
            <motion.li className="list-none font-medium">
              <Link to="/services/residential" className="hover:text-blue-600">
              Residential Pest Control
              </Link>
            </motion.li>
            {/* Commercial Dropdown */}
            <motion.div className="relative font-medium">
              <button
                className="flex items-center space-x-1 hover:text-blue-600"
                onClick={toggleCommercialDropdownOpen}
              >
                <span>Commercial Pest Control</span>
                <ChevronDown className="w-5 h-5" />
              </button>
              {isCommercialDropdownOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="absolute bg-white shadow-md rounded-lg mt-2 w-[500px] p-4 grid grid-cols-3 gap-4"
                  onMouseLeave={() => setIsCommercialDropdownOpen(false)}
                >
                  {ComItems.map((service, index) => (
                    <Link
                      to={`/Commercial/${service
                        .replace(/\s+|\//g, "-")
                        .toLowerCase()}`}
                      key={index}
                      className="block text-gray-800 hover:text-blue-600"
                    >
                      {service}
                    </Link>
                  ))}
                </motion.div>
              )}
            </motion.div>
            
            {/* About Dropdown */}
            <motion.div className="relative font-medium">
              <button
                className="flex items-center space-x-1 hover:text-blue-600"
                onClick={toggleAboutDropdown}
              >
                <span>About</span>
                <ChevronDown className="w-5 h-5" />
              </button>
              {isAboutDropdownOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="absolute bg-white shadow-md rounded-lg mt-2 w-[200px] p-4 grid grid-cols-1 gap-4"
                  onMouseLeave={() => setIsAboutDropdownOpen(false)}
                >
                  {aboutItems.map((item, index) => (
                    <Link
                      to={`/about/${item.replace(/\s+/g, "-").toLowerCase()}`}
                      key={index}
                      className="block text-gray-800 hover:text-blue-600"
                    >
                      {item}
                    </Link>
                  ))}
                </motion.div>
              )}
            </motion.div>

            {/* Services Dropdown */}
            <motion.div className="relative font-medium">
              <button
                className="flex items-center space-x-1 hover:text-blue-600"
                onClick={toggleServicesDropdown}
              >
                <span>Our Services</span>
                <ChevronDown className="w-5 h-5" />
              </button>
              {isServicesDropdownOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="absolute bg-white shadow-md rounded-lg mt-2 w-[500px] p-4 grid grid-cols-3 gap-4"
                  onMouseLeave={() => setIsServicesDropdownOpen(false)}
                >
                  {services.map((service, index) => (
                    <Link
                      to={`/services/${service
                        .replace(/\s+|\//g, "-")
                        .toLowerCase()}`}
                      key={index}
                      className="block text-gray-800 hover:text-blue-600"
                    >
                      {service}
                    </Link>
                  ))}
                </motion.div>
              )}
            </motion.div>
            <motion.li className="list-none font-medium">
              <Link to="/contact" className="hover:text-blue-600">
                Contact
              </Link>
            </motion.li>

            {/* Get A Quote Button */}
            <motion.div>
              <Link
                to="/booking"
                className="px-4 py-2 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700"
              >
                Get A Quote
              </Link>
            </motion.div>
          </div>

          {/* Mobile Hamburger Menu */}
          <div className="md:hidden flex items-center">
            <button onClick={toggleMenu} className="text-gray-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-gray-800 text-white px-4 py-6 space-y-4">
          <motion.li className="list-none font-medium">
            <Link to="/" onClick={closeMenu}>
              Home
            </Link>
          </motion.li>
          <motion.li className="list-none font-medium">
            <button
              onClick={toggleAboutDropdown}
              className="flex justify-between w-full"
            >
              About <ChevronDown className="w-5 h-5" />
            </button>
            {isAboutDropdownOpen && (
              <ul className="mt-2 space-y-2 pl-4">
                {aboutItems.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={`/about/${item.replace(/\s+/g, "-").toLowerCase()}`}
                      onClick={closeMenu}
                    >
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </motion.li>
          <motion.li className="list-none font-medium">
              <Link to="/services/residential" className="hover:text-blue-600">
              Residential Pest Control
              </Link>
            </motion.li>
            <motion.div className="relative font-medium">
              <button
                className="flex justify-between w-full"
                onClick={toggleCommercialDropdownOpen}
              >
                <span>Commercial Pest Control</span>
                <ChevronDown className="w-5 h-5" />
              </button>
              {isCommercialDropdownOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="mt-2 space-y-2 pl-4"
                  onMouseLeave={() => setIsCommercialDropdownOpen(false)}
                >
                  {ComItems.map((service, index) => (
                    <Link
                      to={`Commercial/${service
                        .replace(/\s+|\//g, "-")
                        .toLowerCase()}`}
                      key={index}
                      className="block text-white-800 hover:text-white-600"
                    >
                      {service}
                    </Link>
                  ))}
                </motion.div>
              )}
            </motion.div>
          <motion.li className="list-none font-medium">
            <button
              onClick={toggleServicesDropdown}
              className="flex justify-between w-full"
            >
              Our Services <ChevronDown className="w-5 h-5" />
            </button>
            {isServicesDropdownOpen && (
              <ul className="mt-2 space-y-2 pl-4">
                {services.map((service, index) => (
                  <li key={index}>
                    <Link
                      to={`/services/${service
                        .replace(/\s+|\//g, "-")
                        .toLowerCase()}`}
                      onClick={closeMenu}
                    >
                      {service}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </motion.li>
          <motion.li className="list-none font-medium">
            <Link
              to="/get-a-quote"
              className="block px-4 py-2 bg-blue-600 text-center rounded-lg hover:bg-blue-700"
              onClick={closeMenu}
            >
              Get A Quote
            </Link>
          </motion.li>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
